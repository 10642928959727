header{
 //   position: sticky;
    top: 0;
    z-index: 99;
}
.sidebar-wrap{
    height: calc(100vh - $headerHeight);
    width: 272px;   
    overflow: auto;
    position: sticky;
    top: 92px;
 .navigation-link{
    @extend %flex;
    @extend %alignCenter;
    padding: 11px 27px 11px 57px;
 }
}
.logo-block{
    width: 272px;
}
.navigation-ic{
    width: 24px;
    @extend %flex;
    @extend %alignCenter;

}
.navigation-link{
    color: $white !important;
    text-decoration: none;
    &.active{
        background-color: $white;
        color: $primary !important;
    }
}
.page-block{
    width: calc(100% - 272px);
    padding: 15px 57px;
}
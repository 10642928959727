$primary: #F6511A;
$secondary: #0077FF;
$success: #83B80E;
$warning: #FFB400;
$pink: #9d0f6a;
$white: #fff;
$light: #ECECEC;
$black: #000;
$info: #fff;
$gray: #646464;
$light-gray: #F6F3F3;
$light-primary: #FEEEE8;
$black-secondary: #434343;
$black-ternary: #011627;
$nevy-blue: #23346C;
$text-dark-green: #1D8942;
$orange-light: #FF7426;




.theme-default {
  --primary-color: #F6511A;
  --bs-primary: #F6511A;
  --light-primary:  #FEEEE8;
  .game-card-ic{
    .primary-svg-ic-fill{
      fill: $primary;
    }
    .primary-svg-ic-stroke{
      stroke: $primary;
    }
}
}

.theme-green {
  --primary-color: #83B80E;
  --bs-primary: #83B80E;
  --light-primary:  #f2f8e6;
  .btn-primary{
    --bs-btn-hover-bg: #83B80E;
    --bs-btn-active-bg: #9cc63e;
    --bs-btn-hover-border-color: #8fbf26;
    --bs-btn-active-border-color: #8fbf26;
  }
  .game-card-ic{
    .primary-svg-ic-fill{
      fill: $success;
    }
    .primary-svg-ic-stroke{
      stroke: $success;
    }
}
}

.theme-yellow {
  --primary-color: #FFB400;
  --bs-primary: #FFB400;
  --light-primary:  #fff0cc;
  .btn-primary{
    --bs-btn-hover-bg: #ffbf26;
    --bs-btn-active-bg: #ffc333;
    --bs-btn-hover-border-color: #ffbc1a;
    --bs-btn-active-border-color: #ffbc1a;
  }
  .game-card-ic{
    .primary-svg-ic-fill{
      fill: $warning;
    }
    .primary-svg-ic-stroke{
      stroke: $warning;
    }
}
}

.theme-blue {
  --primary-color: #0077FF;
  --bs-primary: #0077FF;
  --light-primary:  #e5f1ff;
  .btn-primary{
    --bs-btn-hover-bg: #268bff;
    --bs-btn-active-bg: #3392ff;
    --bs-btn-hover-border-color: #1a85ff;
    --bs-btn-active-border-color: #1a85ff;
  }
  .game-card-ic{
      .primary-svg-ic-fill{
        fill: $secondary;
      }
      .primary-svg-ic-stroke{
        stroke: $secondary;
      }
  }
}

.theme-purple {
  --primary-color: #9d0f6a;
  --bs-primary: #9d0f6a;
  --light-primary:  #9d0f6a1a;
  .btn-primary{
    --bs-btn-hover-bg: #9d0f6a;
    --bs-btn-active-bg: #9d0f6a;
    --bs-btn-hover-border-color: #9d0f6a;
    --bs-btn-active-border-color: #9d0f6a;
  }
  .game-card-ic{
      .primary-svg-ic-fill{
        fill: #9d0f6a;
      }
      .primary-svg-ic-stroke{
        stroke: #9d0f6a;
      }
  }
  // .game-info-timeline {
  //   .game-timeline-infobox {
  //     color: #ffffff;
  //   }
  // }
  // .media-section {
  //   .text-black-ternary {
  //     color: #ffffff;
  //   }
    
  // }
}

$headerHeight: 80px;

$headings-font-family: 'Inter', sans-serif;
$body-font-family: 'Inter', sans-serif;
$poppins-font-family: 'Poppins', sans-serif;
$font-weight-medium: 500;
$font-weight-bold: 700;

$table-th-font-weight: 500;
$table-color: $primary;
$table-cell-padding-y: 20px;
$table-cell-padding-x: 20px;
$table-cell-vertical-align: middle;
$table-bg: #EBEBEB;
$table-border-color: #B5B5B5;


$btn-font-family: 'Inter', sans-serif;
$btn-font-size: 18px;
$btn-padding-y: 8px;
$btn-padding-x: 16px;
$btn-border-radius: 14px;
$btn-font-weight: $font-weight-medium;
$btn-border-width: 2px;
$btn-line-height: 1.4;

$modal-content-bg: #722974;
$modal-content-color: #fff;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-content-border-radius: 20px;

$form-label-color: $black;
$form-label-font-size: 16px;
$form-label-font-weight: $body-font-family;
$form-label-margin-bottom: 5px;

$input-border-radius: 40px;
$input-color: $black;
$input-box-shadow: 0;
$input-focus-box-shadow: none;
$input-placeholder-color: #ACACAC;
$input-padding-y: 10px;
$input-padding-x: 20px;

$badge-padding-x: 10px;
$badge-padding-y: 5px;
$badge-border-radius: 10px;
$badge-font-size: 12px;
$badge-font-weight: 700;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  // 4
  2: $spacer * .5,
  // 8
  3: $spacer * .625,
  // 10
  4: $spacer * 0.75,
  //12
  5: $spacer ,
  // 16
  6: $spacer * 1.125,
  //18
  7: $spacer * 1.25,
  //20
  8: $spacer * 1.375,
  //22
  9: $spacer * 1.5,
  //24
  10: $spacer * 1.75,
  // 28
  11: $spacer * 2,
  // 32
  38: $spacer * 2.375,
  //38
  40: $spacer * 2.5,
  //40
  50: $spacer * 3.125,
  //50
  52: $spacer * 3.25,
  //52
  60: $spacer * 3.75,
  //60
  70: $spacer * 4.37,
  //70
  80: $spacer * 5,
  //80
  85: $spacer * 5.31,
  //85
  150: $spacer * 9.375,
  //150

 
  // 11: $spacer * 2, // 32

);

$h1-font-size: 32px;
$h2-font-size: 24px;
// $h3-font-size: 40px;
$h4-font-size: 24px;
$h5-font-size: 20px;
// $h6-font-size: 24px;

$enable-negative-margins: true;


$font-size-base: 1rem; // Assumes the browser default, typically `16px` 
// Font size
$font-sizes: (
  10: $font-size-base * 0.625,
  12: $font-size-base * 0.75,
  14: $font-size-base * 0.875,
  16: $font-size-base,
  18: $font-size-base * 1.125,
  20: $font-size-base * 1.25,
  22: $font-size-base * 1.375,
  24: $font-size-base * 1.5,
  26: $font-size-base * 1.62,
  28: $font-size-base * 1.75,
  32: $font-size-base * 2,
  34: $font-size-base * 2.125,
  36: $font-size-base * 2.25,
  40: $font-size-base * 2.5,
  46: $font-size-base * 2.875,
  48: $font-size-base * 3,
  50: $font-size-base * 3.75,
  60: $font-size-base * 3.75,
);
.btn {
    transition: 0.3s all;
    --bs-btn-padding-y: 6px;
    --bs-btn-padding-x: 20px;
    &:focus-visible{
        box-shadow: none;
    }

    &-link {
        padding: 0;
        border-width: 0 0 1px 0;
        border-radius: 0;
        --bs-btn-font-weight: 400;
        --bs-btn-color: var(--bs-black);
        --bs-btn-bg: transparent;
        --bs-btn-border-color: var(--bs-black);
        --bs-btn-hover-color: var(--bs-primary);
        --bs-btn-hover-border-color: var(--bs-primary);
        --bs-btn-active-color: var(--bs-link-hover-color);
        --bs-btn-active-border-color: var(--bs-primary);
        --bs-btn-disabled-color: #6c757d;
        --bs-btn-disabled-border-color: transparent;
        --bs-btn-box-shadow: none;
        --bs-btn-focus-shadow-rgb: 209, 69, 22;
        text-decoration: none;
    }

    &-primary {
        --bs-btn-color: #fff;
        --bs-btn-bg: var(--bs-primary);
        --bs-btn-border-color:  var(--bs-primary);
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #f76b3c;
        --bs-btn-hover-border-color: #f76231;
        --bs-btn-focus-shadow-rgb: 209, 69, 22;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #f87448;
        --bs-btn-active-border-color: #f76231;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg:  var(--bs-primary);
        --bs-btn-disabled-border-color:  var(--bs-primary);
    }

    &-success{
        --bs-btn-color: var(--bs-white);
        --bs-btn-hover-color: var(--bs-white);
        --bs-btn-active-color: var(--bs-white);
    }

    &-warning{
        --bs-btn-color: var(--bs-white);
        --bs-btn-hover-color: var(--bs-white);
        --bs-btn-active-color: var(--bs-white);
    }

    &-secondary{
        --bs-btn-color: var(--bs-white);
        --bs-btn-hover-color: var(--bs-white);
        --bs-btn-active-color: var(--bs-white);
    }

    &-secondary {
        color: $white;
        box-shadow: 0px 4px 4px rgba(39, 174, 96, 0.2);

        svg {
            margin-top: -2px;
        }

        &:hover {
            color: $white !important;
            // background-color: $secondary !important;
        }
    }

    &-info {
        color: $primary;
    }


    &-outline-info {
        border-width: 1px;
    }

    &-white {
        background-color: $white;
        color: var(--primary-color);
        border: 1px solid $white;

        &:hover,
        &:focus {
            background-color: transparent;
            border-color: $white;
            color: $white;
        }
    }

}

.bg-primary{
    background-color: var(--primary-color) !important;
}
.text-primary{
    color: var(--primary-color) !important;
}
.border-primary{
    border-color: var(--primary-color) !important;
}
.btn-link{
    transition: 0.3s all;
    &:hover{
        path{
            stroke: var(--primary-color);
            transition: 0.3s all;
        }
    }
}
.form-control {
    &:focus {
        @include placeholder {
            color: var(--primary-color);
        }
    }

    border-radius: 1.5rem !important;
}
.font-60{
    font-size: 46px;
    @media only screen and (max-width: 991px) {
        font-size: 36px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 32px;
    }
}
.font-24{
    font-size: 18px;
    @media only screen and (max-width: 767px) {
        font-size: 16px;
    }
}
.font-36{
    font-size: 30px;
    @media only screen and (max-width: 991px) {
        font-size: 24px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 22px;
    }
}
.font-32{
    font-size: 26px;
    @media only screen and (max-width: 767px) {
        font-size: 22px;
    }
    @media only screen and (max-width: 576px) {
        font-size: 16px;
    }
}
.font-34{
    font-size: 30px;
    @media only screen and (max-width: 991px) {
        font-size: 24px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 18px;
    }
    @media only screen and (max-width: 576px) {
        font-size: 18px;
    }
}
.font-26{
    font-size: 22px;
    @media only screen and (max-width: 767px) {
        font-size: 18px;
    }
}

.font-16{
    font-size: 16px;
}
.font-18{
    font-size: 18px;
    @media only screen and (max-width: 991px) {
        font-size: 16px;
    }
}
.font-14{
    font-size: 14px;
}
.font-58{
    font-size: 44px;
    @media only screen and (max-width: 991px) {
        font-size: 36px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 26px;
    }
}
.font-38{
    font-size: 34px;
    @media only screen and (max-width: 991px) {
        font-size: 28px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 22px;
    }
}

.font-44{
    font-size: 38px;
    @media only screen and (max-width: 991px) {
        font-size: 30px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 24px;
    }
}

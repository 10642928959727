.logo-blockwrap{
    max-width: 180px;
}
.authentication-page{
    height: 100vh;
}
.w-md-full{
    @include media-breakpoint-down(md) {
        width: 100% !important;
    }
}
.social-btn {
    width: 32px;
}
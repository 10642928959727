.game-info-card {
    background-color: $light-gray;
    border: 1px solid $light-gray;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    transition: 0.3s all;

    &:hover{
        border-color:  var(--primary-color);
    }

    .card-label {
        background-color: $white;
        color:  var(--primary-color);
        border-radius: 0 10px 10px 0;
        padding: 2px 20px;
        position: absolute;
        left: 0;
        top: 15px;
    }

    .top-card-info {
        background-color: var(--primary-color);
        border-radius: 15px 15px 340px 340px;
        padding: 67px 20px 32px;
        text-align: center;
    }

    .bg-vector {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }

    .bottom-card-info {
        padding: 25px 20px 44px;
    }
}

.game-card-ic {
    width: 106px;
    height: 106px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border-radius: 50%;
    position: relative;
    @include media-breakpoint-down(lg) { 
        width: 80px;
        height: 80px;
        padding: 18px;
    }

    &::before {
        content: '';
        position: absolute;
        border: 10px solid $white;
        left: -10px;
        right: -10px;
        top: -10px;
        bottom: -10px;
        border-radius: 50%;
        opacity: 0.1;
        @include media-breakpoint-down(lg) { 
            border: 5px solid $white;
            left: -5px;
            right: -5px;
            top: -5px;
            bottom: -5px;
        }
    }
}

.media-section{
    padding: 110px 0;
    background: var(--light-primary);
    @include media-breakpoint-down(lg) { 
        padding: 50px 0;
    }
    .grid-image-wrap{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3px; 
        max-width: 486px;
    }
}

.game-small-box{
    padding: 10px 10px 0;
}


// Game Timeline

.game-info-timeline{
    max-width: 1103px;
    margin: 0 auto;
}
.game-timeline-wrap {
    position: relative;
    @include media-breakpoint-down(md) { 
        background-color: var(--light-primary);
        padding: 30px 20px;
        border-radius: 16px;
    }
    @include media-breakpoint-down(md) { 
        flex-direction: column;
    }
    &:not(:last-child){
        margin-bottom: 40px;
        @include media-breakpoint-down(lg) { 
         margin-bottom: 28px;
        }
        &::after{
            content: "" ;
            position: absolute;
            top: 120px;
            left: 0;
            background: url('../../../../public/assets/images/dot-line-arrow.svg') no-repeat;
            height: 254px;
            background-size: contain;
            width: 80px;
            background-position: bottom;
            @include media-breakpoint-down(lg) { 
                display: none;
            }
        }
    }
    .game-timeline-ic{
        width: 106px;
        height: 106px;
        margin-right: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-color);
        border-radius: 50%;
        @include media-breakpoint-down(lg) { 
            width: 80px;
            height: 80px;
            margin-right: 10px;
            padding: 20px;
        }
         @include media-breakpoint-down(md) { 
            margin-bottom: 20px;
         }
    }
    .game-timeline-infobox{
        background-color: var(--light-primary);
        border-radius: 0px 60px 60px 60px;
        padding: 20px 30px;
        height: 350px;
        width: calc(100% - 124px);
        @include media-breakpoint-down(lg) { 
            height: auto;
            border-radius: 0px 20px 20px 20px;
            padding: 20px 30px 40px;
            width: calc(100% - 90px);
        }
        @include media-breakpoint-down(md) { 
            background-color: transparent;
            padding: 0;
            width: 100%;
        }
    }
}

.profile-image {
    width: 40px;
    height: 40px;
}

header {
    height: $headerHeight;
    width: 100%;
    background-color: $light-gray;
}

.icon-bx {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    margin-top: 2px;
}

.searchform {
    .form-control {
        padding-left: 30px;

        @include placeholder {
            color: #fff !important;
            opacity: 1 !important;
        }
    }
}

.navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mobile-button {
        display: none;

        @include media-breakpoint-down(lg) { 
            display: block;
            margin-top: 10px;
        }
    }

    .md-button {
        display: block;

            @include media-breakpoint-down(lg) { 

            display: none;
        }
    }
}

.navbar-collapse {
    flex-grow: inherit;
    z-index: 9;

    @include media-breakpoint-down(lg) { 
        border: 1px solid #ececec;
        border-radius: 20px;
        padding-bottom: 30px;
        position: fixed;
        inset: 80px 0px 0px;
        border-radius: 0px;
        background: $white;
        transition: 0.3s all;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        transform: scaleX(-10%);
    }
    &.collapsing{
        height: inherit;
    }
    &.show{
        @include media-breakpoint-down(lg) { 
            opacity: 1;
            visibility: visible;
            transform: scaleX(1);
            transition: 0.3s all;
        }
    }
}


.footer-wrap{
    max-width: 1440px;
    margin: 0 auto;
}
footer {
    background: $black;

    .footer-logo {
        display: flex;

        @media only screen and (max-width: 767px) {
            width: 150px;
        }
    }
    .reach-us-ic {
        width: 32px;
        text-align: center;
    }
}
.footer-block{
    @include media-breakpoint-down(md) {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba($white, 0.4);
    }
}
.top-footer {
    border-bottom: 1px solid rgba($white, 0.4);
}

.bottom-footer {
    text-align: left;
    padding: 40px 0;

    @media only screen and (max-width: 767px) {
        padding: 20px 0;
    }

    .sub-title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        color: $white;
        margin-bottom: 20px;
    }
}

.footer-listing {
    padding-left: 0;
    margin-bottom: 0;

    li {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        list-style-type: none;
        a{
            color: $white;
            text-decoration: none;
            transition: 0.5s all;
            &:hover{
                color:  var(--primary-color);
            }
        }
    }
}

.newsletter-box {
    border-radius: 10px;
    background: #131313;
    padding: 14px;

    .form-control {
        border-radius: 4px 0 0 4px !important;
        background: #1e1e1e;
        border: none;
        color: #fff;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;

        &::placeholder {
            font-weight: 400;
            font-size: 11px;
            line-height: 16px;
            color: #616161;
        }
    }

    .input-group-text {
        border-radius: 4px;
        background: $black;
        border: none;
        color: #fff;
    }

    p {
        margin-top: 15px;
        margin-bottom: 0;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.5);
    }
}

.navbar-brand {
    font-size: 33px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: $poppins-font-family;

    @media only screen and (max-width: 767px) {
        width: 150px;
    }
}
.uc-dropdown{
    .nav-link{
        &:hover{
            border: 0 !important;
        }
    }
    .dropdown-menu {
        min-width: 64px;
        left: auto;
        right: 0;
    }
}
.dropdown-item.active, .dropdown-item:active{
    background-color:  var(--primary-color);
}
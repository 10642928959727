.height-220{
    height: 220px;
}
.card-body{
    padding: 35px 20px;
}
.badge {
    font-size: 14px;
}
.gamelisting-section{
    padding: 65px 0;
    .card{
        border-radius: 8px;
        overflow: hidden;
        transition: 0.3s all;
        border: 1px solid transparent;
        box-shadow: 0px 4px 25px 0px #0000001A;
        background-color: $white;
        &:hover{
            border-color: var(--bs-primary);
        }
    }
}
.banner-wrapper{
    padding: 80px 0;
    @media only screen and (max-width: 767px) {
        padding: 80px 0 58px;
    }
    h1{
        max-width: 630px;
        margin-left: auto;
        margin-right: auto;
    }
}

.logos-block{
    padding: 14px 20px;
    background: #FFFBFB;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
}

.customer-section{
    padding: 75px 0;
}
.slide-box{
    background: #FFFFFF;
    box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
}
.company-testimonialsection{
    padding: 60px 0;
}
.height-250{
    height: 250px;
}
.radius-20{
    border-radius: 20px;
}
.border-bottomsmall::after{
    content: '';
    height: 4px;
    width: 90%;
    max-width: 216px;
    background: $primary;
    display: block;
    margin: 33px 0 27px;
    @media only screen and (max-width: 767px) {
        margin: 20px 0 20px;
    }
}
.event-details{
    padding: 46px 0 98px;
}
.toplheader{
    padding: 20px 0;
    border-radius: 30px 30px 0px 0px;
    background: #662168;
}
.event-datablock{
    padding: 12px 20px;
    &:not(:last-child){
        border-bottom: 1px solid #8C388F;
    }
}
.slider-space20{
    .slick-slide{
        padding: 30px 10px;
    }
    .slick-list{
        margin: 0 -20px;
        // padding: 0 10px;
    }
    .slick-track{
        // padding: 0 10px;
        display: flex;
        min-width: 100%;
    }
}
.slide-box{
    padding: 40px
}


.gaming-details{
    padding: 100px 0 40px;
    @media only screen and (max-width: 767px) {
        padding: 40px 0 30px;
    }
}
.gaming-image{
    position: relative;
    &:before{
        content:"";
        position: absolute;
        width: 100%;
        height: 143px;
        background: $primary;
        z-index: -1;
        top: -20px;
        left: -20px;
        @media only screen and (max-width: 767px) {
            left: 0;
        }
    }
}
.gaming-detailes{
    padding-left: 25px;
    max-width: 450px;
    text-align: left;
    @media only screen and (max-width: 767px) {
        padding-left: 0;
        padding-top: 20px;
    }
}
.ct-title{
    font-size: 32px;
    line-height: 38px;
}








    .cnd-card{
        background: #f0f0f0;
        border-radius: 24px;
    }
    .cnd-img{
        border-radius: 24px 24px 0 0;
        overflow: hidden;
        img{
            object-fit: cover;
            width: 100%;
        }
    }
    .cnd-content{
        padding: 0 20px 30px;
        h4{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 34px;
            padding: 10px 0;
            border-bottom: 1px solid #d9d9d9;
            letter-spacing: 0.02em;
            color: #23346C;
        }
        p{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #011627;
            padding: 10px 0;
        }
    }


.why-choose-section{
    padding: 100px 0;
    @media only screen and (max-width: 991px) {
        padding: 60px 0;
    }
    @media only screen and (max-width: 767px) {
        padding: 30px 0;
    }
}

.title{
    display: flex;
    align-items: center;
    justify-content: center;
    h2{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        line-height: 53px;
        letter-spacing: 0.02em;
        color: #FDFFFC;
        position: relative;
        padding-bottom: 20px;
        @media only screen and (max-width: 767px) {
            line-height: 40px;
        }
        &::before{
            content: "";
            position: absolute;
            left: 22%;
            bottom: 0;
            width: 60%;
            height: 2px;
            background: #fff;
            transform: translate(0,-50%);
        }
    }
}
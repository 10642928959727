.main-banner {
    padding: 56px 0;
}

.home-banner-img-wrap {
    position: relative;
    width: 100%;
    max-width: 582px;
    margin-left: auto;

    .home-banner-img {
        z-index: 10;
    }
}

.normal-header {
    .nav-item {

        &:nth-child(4),
        &:nth-child(5) {
            .nav-link {
                @include media-breakpoint-up(lg) {
                    color: $white;
                }
            }
        }
    }

    .uc-dropdown {
        .nav-link {
            color: $white;
            @include media-breakpoint-down(lg) {
                color: $black;
            }
        }
    }
}

.simple-header {
    .section-bg-corner {
        display: none;
    }

    .nav-item {

        &:nth-child(4),
        &:nth-child(5) {
            .nav-link {
                @include media-breakpoint-up(lg) {
                    color: $gray;
                }
            }
        }
    }

    .uc-dropdown {
        .nav-link {
            color: $gray;
        }
    }

    .btn-white {
        background-color: var(--primary-color);
        color: $white;
        border: 1px solid var(--primary-color);

        &:hover {
            background-color: transparent;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
        }
    }
}

.scrolled-header {
    .section-bg-corner {
        display: none;
        opacity: 0;
        transition: 0.6s all;
    }

    .btn-white {
        background-color: var(--primary-color);
        color: $white;
        border: 1px solid var(--primary-color);

        &:hover {
            background-color: transparent;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
        }
    }
}

.section-bg-corner {
    position: absolute;
    background: var(--primary-color);
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    z-index: 9;
    transition: 0.6s all;


    

    @include media-breakpoint-down(lg) {
        display: none;
    }
}






.game-produce-section {
    padding: 52px 0 47px;
}
.trending-section{
    padding-top: 30px;
    position: relative;
}
.top-game-boxicon{
    width: max-content;
    padding: 11px 20px;
    border-radius: 14px 14px 0px 0px;
    z-index: 1;
}
.game-box {
    border-radius: 0 20px 20px 20px;
    overflow: hidden;
    z-index: 1;
    position: relative;
    .game-box-img{
        height: 289px;
        width: 100%;
        @include media-breakpoint-down(xl) {
            height: 220px;
        }
        @include media-breakpoint-down(sm) {
            height: 200px;
        }
    }
   

    .btn-success {
        --bs-btn-bg: #83B80E;
        --bs-btn-border-color: #83B80E;
        --bs-btn-hover-bg: #83B80E;
        --bs-btn-active-bg: #9cc63e;
        --bs-btn-hover-border-color: #8fbf26;
        --bs-btn-active-border-color: #8fbf26;
    }
}

.game-boxicon {
    width: 126px;
    height: 126px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    box-shadow: 0px 4px 4px 0px #00000040;

    @include media-breakpoint-down(xxl) {
        width: 100px;
        height: 100px;
        padding: 24px;
    }

    @include media-breakpoint-down(lg) {
        width: 88px;
        height: 88px;
        padding: 20px;
    }
}

.trending-slider {
    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;

        >div {
            height: 100%;
            padding: 14px 14px 18px;
            // padding-bottom: 18px;
        }
    }
}

.trending-card {
    margin-bottom: 20px;
    height: 100%;

    .btn {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 16px;
    }
}


.trending-card {
    position: relative;
    padding: 10px 10px 30px;
    // box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    // border: 1px solid $white;
    transition: 0.5s all;
    color: $white;
    padding-bottom: 66px !important;

    // &:hover {
    //     border-color: var(--primary-color);
    // }

    .trending-image {
        width: 100%;
        height: 196px;

        img {
            border-radius: 14px;
        }
    }
    &.dark-cityquest {
        .btn-white{
            color: $success;
            &:hover{
                color: $white;
            }
        }
    }
    &.dark-homequest {
        .btn-white {
            color: $secondary;
            &:hover{
                color: $white;
            }
        }
    }
    &.dark-officequest {
        .btn-white {
            color: $warning;
            &:hover{
                color: $white;
            }
        }
    }
    &.dark-schoolquest {
        .btn-white {
            color: $primary;
            &:hover{
                color: $white;
            }
        }
    }
    &.dark-hollowing{
        .btn-white {
            color: #9d0f6a;
            &:hover{
                color: $white;
            }
        }
    }
  
}

.who-we-are-section {
    margin-bottom: 90px;
}

.who-we-are-img-wrap {
    position: relative;
    display: block;
    max-width: 474px;
    margin: 0 auto;

    &::before {
        content: '';
        position: absolute;
        background: var(--primary-color);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 30px;
        transform: rotate(5.22deg);
        z-index: -1;
        display: block;
    }
}

.contact-container {
    padding: 95px 0 122px;

    @include media-breakpoint-down(lg) {
        padding: 50px 0;
    }
}

.contact-wrapper {
    max-width: 1050px;
    margin: 0 auto;
    padding: 32px 104px 80px;
    background-color: var(--primary-color);
    position: relative;

    @include media-breakpoint-down(lg) {
        padding: 30px;
    }

    .form-control {
        height: 50px;
        border-color: $white;
        color: $white;

        &.form-textarea {
            height: auto;
        }
    }

    .contact-bg {
        position: absolute;
        right: 0;
        top: 57px;
        z-index: 1;
    }

    form {
        position: relative;
        z-index: 2;
    }
}

.title-border {
    width: fit-content;
    margin: 0 auto;
    position: relative;
    padding-bottom: 4px;

    &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
        height: 2px;
        background: #D9D9D9;

    }
}


.why-choose-us-section {
    padding: 78px 0 70px;
}


.accordion-green {
    &.accordion-item {
        border: 1px solid rgba($success, 0.3);
    }

    .accordion-button,
    .accordion-body {
        background: rgba($success, 0.1);
    }
}

.accordion-blue {
    &.accordion-item {
        border: 1px solid rgba($secondary, 0.3);
    }

    .accordion-button,
    .accordion-body {
        background: rgba($secondary, 0.1);
    }
}

.accordion-orange {
    &.accordion-item {
        border: 1px solid rgba($primary, 0.3);
    }

    .accordion-button,
    .accordion-body {
        background: rgba($primary, 0.1);
    }
}

.testimonial-slide {
    display: flex !important;
    height: 100%;

    @include media-breakpoint-down(lg) {
        flex-direction: column;
        background-color: $white;
        border-radius: 12px;
        padding: 20px;
    }

    .testimonial-img {
        width: 328px;
        margin-right: 30px;

        @include media-breakpoint-down(lg) {
            width: 100px;
            height: 100px;
            margin-right: 0;
            margin-bottom: 10px;
        }

        img {
            border-radius: 10px;
        }
    }

    .testimonial-content {
        width: calc(100% - 358px);
        background-color: $white;
        border-radius: 12px;
        padding: 20px;

        @include media-breakpoint-down(lg) {
            width: 100%;
            background-color: transparent;
            border-radius: 0;
            padding: 0;
        }


    }
}

.testimonial-sliderwrapper {
    max-width: 1050px;
    margin: 0 auto;

    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;
    }

    .slick-slide>div {
        height: 100%;
    }

    .slick-prev,
    .slick-next {
        width: 32px;
        height: 20px;
        z-index: 1;
        bottom: 32px;
        background-size: 32px !important;
        top: auto;
        transform: translate(0, 0);

        &::before {
            content: '';
            width: 32px;
            height: 20px;
            display: block;
        }
    }

    .slick-next {
        right: 20px;

        &::before {
            background: url('data:image/svg+xml,%3Csvg width="26" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1 7H25M25 7L19.375 1M25 7L19.375 13" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E') no-repeat center center;

        }
    }

    .slick-prev {
        left: auto;
        right: 52px;
        margin-right: 10px;

        &::before {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="26" height="14" viewBox="0 0 26 14" fill="none"><path d="M25 7H1M1 7L6.625 1M1 7L6.625 13" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center center;
        }
    }
}

.game-info-slider {

    .slick-prev,
    .slick-next {
        width: 32px;
        height: 20px;
        z-index: 1;
        background-size: 32px !important;

        &::before {
            content: '';
            width: 32px;
            height: 20px;
            display: block;
        }
    }

    .slick-next {
        right: -15px;

        &::before {
            background: url('data:image/svg+xml,%3Csvg width="26" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1 7H25M25 7L19.375 1M25 7L19.375 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E') no-repeat center center;

        }
    }

    .slick-prev {
        left: -15px;

        &::before {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="26" height="14" viewBox="0 0 26 14" fill="none"><path d="M25 7H1M1 7L6.625 1M1 7L6.625 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center center;
        }
    }
}

.homequest-section {
    .section-title {
        border-bottom: 2px solid;
    }

    .homequest-img-wrap {
        position: relative;
        padding: 24px 0 0 24px;
      
       
        &::before {
            content: '';
            background-color: var(--primary-color);
            position: absolute;
            top: 0;
            left: 0;
            height: 194px;
            width: calc(100% - 24px);
            z-index: 1;
        }

        img {
            position: relative;
            z-index: 2;
            height: 500px;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}


// sorting btns
.sorting-btns{
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    gap: 24px;
    padding-bottom: 45px;
    .sort-btn{
        background-color: $primary;
        color: $white;
        padding: 12px 30px 8px 30px;
        border-radius: 14px 14px 0 0;
        cursor: pointer;
        &.active{
            background-color: #343434;
        }
    }
}